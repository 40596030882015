.file-error {
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
    color: #9aa9bb;
}

.file-error-message {
    color: #b60606;
}
.selected-pdf-container{
    display: none;
}
.pdf-file-delete, .delete{
    cursor: pointer;
}

#inner_drop_sec .react-pdf__Document {
    box-shadow: none !important;
}
.apply_all, .dropdownsSelect .modal-link{
    cursor: pointer;
}
@media screen and (min-width:576px) {
    #inner_drop_sec .react-pdf__Page canvas {
        max-width: 100%;
        height: 150px !important;
        object-fit: cover;
        box-shadow: 0px 0px 4px #b8b8b8;
        border: 0px;
        border-radius: 5px;
       
    }
}