
@font-face {
  font-family: 'Acumin Pro';
  src: url('font/acumin-pro/Acumin-RPro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap; /* Optional: helps prevent FOIT (Flash of Invisible Text) */
}

/*header_section css*/

/*sticky header position*/


html,
body {
  height: 100%;
}

#__next,
#__next>div {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

body {
  /* display: flex; */
  /* flex-direction: column; */
  font-family: 'Acumin Pro';
  font-style: normal;
  /*font-weight: normal;
    min-height: 800px;*/
}

/*header section start*/

body.offcanvas-active {
  overflow: hidden;
}

.text-purple {
  color: #000001;
}

.navbar {
  box-shadow: -1px 4px 6px #00000026;
}

.offcanvas-header {
  display: none;
}

.screen-overlay {
  width: 0%;
  height: 100%;
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(34, 34, 34, 0.6);
  transition: opacity 0.2s linear, visibility 0.1s, width 1s ease-in;
}

.screen-overlay.show {
  transition: opacity 0.5s ease, width 0s;
  opacity: 1;
  width: 100%;
  visibility: visible;
}

.py-footer {
  background: #fff
}

a.navbar-brand:hover {
  background: #e9ecef;
  border-radius: 10px;
  /* padding: 5px; */
  transition: all .3s ease-in-out;
}

.social-share-btn {
  position: absolute;
  right: 10px;
}

.social-share-btn a {
  color: #fff
}

.loader-screen {
  display: flex;
  justify-content: center;
}

@media all and (max-width: 992px) {
  .offcanvas-header {
    display: block;
  }

  .mobile-offcanvas {
    /*visibility: hidden;
        transform: translateX(-100%);
        border-radius: 0;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 1200;
        width: 55%;
        overflow-y: scroll;
        overflow-x: hidden;
        transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
        background-color: #fff !important;*/
    visibility: hidden;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    display: block;
    position: fixed;
    top: 93px;
    left: 15px;
    z-index: 9999999;
    width: 190px;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-transition: visibility 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    transition: visibility 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
    transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    background-color: #fff !important;
    border-radius: 7px;
    padding: 13px 0px;
  }

  html:lang(ar) .mobile-offcanvas {
    transform: translateX(100%);
  }

  .mobile-offcanvas.show {
    visibility: visible;
    transform: translateX(0);
  }

  /*.nav-cont {*/
  /*  display: block !important;*/
  /*  padding-right: auto !important;*/
  /*  padding-left: auto !important;*/
  /*}*/

  .nav-cont {
    display: block; /* Removed !important, adjust as necessary */
    padding-right: 0; /* Adjust value as needed */
    padding-left: 0;  /* Adjust value as needed */
  }


  .navbar2 {
    box-shadow: 0px 3px 17px #00000047;
  }

  #navbar_main {
    box-shadow: 0px 3px 17px #00000047;
  }

  .dropdown-menu {
    border: none;
  }

  .nav-item {
    border-bottom: 0px solid #eaeaea;
    margin: 0 !important;
    padding: 0 15px 0 25px;
    position: relative;
  }

  .nav-item.active::after {
    content: "";
    left: 0;
    top: 2px;
    bottom: 2px;
    width: 5px;
    background: #558EFF;
    position: absolute;
    border-radius: 0px 10px 10px 0;
  }
}

nav.navbar2 {
  background: #fbefe0;
}

#navbar_main {
  background: #fff;
}

.nav-cont a.nav-link {
  color: #558EFF !important;
}

.nav-cont .nav-item.active a,
.nav-cont .nav-item a:hover {
  color: #3355B4 !important;
}

.nav-cont a.nav-link:hover {
  color: #032a76 !important;
}

/*end*/

/*translate sec*/

.language-menu li {
  border-radius: 10px;
  padding: 10px;
}

.language-menu li:hover,
.language-menu li.active {
  background-color: #f7f7f7;
  color: #000000 !important;
}

.mobile-language-menu {
  display: none;
}

/*.language-menu li.active{
    font-weight: 600;
    border-bottom: 2px solid #020c67;
}*/

div#translator_sec {
  margin-top: 70px;
}

div#translator_sec ul {
  padding: 0;
  list-style-type: none;
  white-space: nowrap;
}

.tab-heading-01.active-tab {
  font-weight: 600;
}

div#translator_sec ul li.tab-heading-01 {
  display: inline-block;
  margin: 0px 30px;
  margin-left: 0;
  /*font-size: 25px;*/
  /*font-weight: 600;*/
  cursor: pointer;
  color: #020c67;
}

.tab-heading-01.active-tab {
  /*display: block !important;*/
}

.active-tab {
  border-bottom: 2px solid #001263;
}

.search-container input[type="text"] {
  width: 100%;
  border: 0;
  box-shadow: 0px 2px 4px #d2d2d2;
  padding: 6px 9px;
  border-radius: 4px;
}

.search-container input[type="text"]:focus {
  outline: none;
}

.print_head.mobile {
  display: none;
}

h4.print_head {
  color: #000001;
  /* margin-top: 26px; */
  font-weight: 700;
  /*font-size: 35px;*/
  margin: 0px;
  font-size: 26px;
  line-height: 33px;
}

.print_head small {
  font-size: 16px;
}

.printout-thumbnails #thumbnails {
  justify-content: left;
}

#thumbnails .cols .thumbnail-img {
  box-shadow: 0px 0px 6px #ddd;
  border-radius: 10px;
  max-width: 88%;
  width: 88%;
}

#thumbnails .cols {
  text-align: center;
}

.tab-heading-01 {
  /*display: none !important;*/
}

#thumbnails .text-left {
  margin-left: 20px;
  margin-top: 15px;
  color: #051b7d;
}

.wrap_img_sec .react-pdf__Page,
.wrap_img_sec .react-pdf__Page canvas {
  height: 250px !important;
}

#thumbnails div .wrap_img_sec:hover .view_icons p:last-child {
  margin-bottom: 0px;
}

.show-more a {
  text-decoration: none;
}

.show-more a:hover {
  color: #051b7d;
}

.printout-thumbnails .view_icons {
  transition: all 3s ease;
  display: none;
}

.printout-thumbnails .wrap_img_sec {
  position: relative;
}

.printout-thumbnails .wrap_img_sec .pdf_data_outer {
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #000C674D;
}

.printout-thumbnails .wrap_img_sec .pdf_data_outer .image {
  max-width: 100%!important;
  aspect-ratio: 1;
}
div#publictaion_sec {
  margin-top: 25px;
  padding-top: 20px;
  /*border-top: 2px solid #ececec;*/
  border-bottom: 2px solid #ececec;
  margin-bottom: 20px;
}

div#publictaion_sec p span {
  font-size: 12px;
}

div#publictaion_sec {
  margin-top: 25px;
  border-bottom: 2px solid #ececec;
  margin-bottom: 20px;
  color: #001263;
  font-weight: 500;
}

#inner_drop_sec img {
  max-width: 100%;
  height: 172px;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 8px;
}

select#sel1 {
  border: 0;
  box-shadow: 0px 3px 8px #c5c5c5;
}

.pdf_sec {
  margin-bottom: 0;
  margin-top: 38px;
  color: #020c67;
  font-weight: 500;
}

div#inner_drop_sec {
  border-bottom: 2px solid #ececec;
  margin-bottom: 20px;
}

.upload-details {
  margin-top: 45px;
}

.printout-text h5 {
  /*font-size: 25px;*/
  font-weight: 600;
}

.printout-text span {
  /*font-size: 20px;*/
  font-weight: normal;
}

.mobile-head .navbar-brand img {
  width: 180px;
}

.mobile-head .navbar-brand {
  margin-right: 0;
}

.mobile-head.navbar-light .navbar-toggler {
  border: none !important;
  color: transparent;
  padding: 0;
}

.printout-thumbnails #thumbnails .cols {
  margin: 20px 0;
}

.publication-block {
  padding: 15px 0;
}

.show-more p {
  font-size: 20px;
  font-weight: normal;
}

.publication-cards-hover {
  transition: transform .2s;
}

.button-hover {
  transition: transform .2s;
}

.button-hover-true:hover {
  transform: scale(1.5) !important;
}

.publication-card-hover:hover {
  transform: scale(1.05);
}

.publication-cards a {
  text-decoration: none;
}

.publication-card .card-image div {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #000C674D !important;
  border-radius: 5px!important;
}

.publication-card .card-image div :hover{
  box-shadow: 2px 6px 6px #000C674D !important;
}

.publication-card .card-image img {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #000C674D !important;
  border-radius: 5px!important;
}

.publication-card .card-image img :hover{
  box-shadow: 2px 6px 6px #000C674D !important;
}

.publication-card .card-title {
  /*font-size: 25px;*/
  font-weight: 700;
  font-size: 15px;
  line-height: 28px;
  color: #000001 !important;
}

.publication-cards .col {
  max-width: 50%;
}

/*.publication-card:hover img{
    box-shadow: 0px 3px 6px #57cfff;
}*/
.publication-card:hover .card-title {
  color: #01b7ff;
}

.view_icons a {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  font: normal normal bold 20px/28px Acumin Pro;
}

.view_icons a:hover {
  color: #d4cece !important;
  text-decoration: underline !important;
}

.desktop-head {
  height: 72px;
}

/*.desktop-head .nav-cont .nav-item {
    font-size: 20px;
}*/

.no_publication_text,
.no_publicationall_text,
.no_periodprintout_text {
  text-align: center;
  font-size: 18px;
  color: #030c68;
  font-family: Acumin Pro;
}

.myupload_error {
  text-align: center;
  padding: 50px;
  font-size: 18px;
  color: #030c68;
  font-family: Acumin Pro;
}

.login-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  /* padding: 20px; */
  height: 300px;
}

/* .login-block .form-content {
  height: 300px;
} */

.login-block .form-content {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px !important;
  width: 100%;
  max-width: 450px;
  position: relative;
  /*padding: 0px;*/
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

.login-block p {
  font-size: 25px;
  line-height: 31px;
  color: #000001;
  margin-bottom: 10px !important;
}

.login-button {
  color: #000001 !important;
  font-size: 16px;
  font-weight: 600;
  background: #F0F1F3 0% 0% no-repeat padding-box;
  -webkit-box-shadow: 0px 2px 5px #e2e2e2;
  box-shadow: 0px 2px 5px #e2e2e2;
  border-radius: 5px;
  opacity: 1;
  border: 0;
  line-height: 37px;
  letter-spacing: 1px;
  width: 360px;
  max-width: 100%;
}

.login-button svg {
  float: left;
}

.login-button:hover {
  text-decoration: none;
  color: #051b7d;
}

.pdf-page-content {
  padding-top: 120px;
  padding-bottom: 140px;
}

.pdf-slide {
  box-shadow: 0px 3px 6px #000C674D;
}

.pdf-slider .pagination {
  display: inline-flex !important;
  margin-top: 12px;
}

.pdf-slider .pagination .page-item:first-child .page-link,
.pdf-slider .pagination .page-link {
  border: none;
  color: #000001;
  opacity: 0.9;
  background: transparent;
}

.pdf-slider .pagination .page-link:focus,
.page-link:hover {
  color: #000C67;
  opacity: 1;
  background-color: transparent;
}

.pdf-slider .pagination .page-link i {
  font-size: 28px;
}

.img-prp {
  position: absolute;
  top: 22px;
  right: 15px;
}

.pdf-head h4 {
  font-weight: 600;
}

.pdf-head h4 a:hover {
  color: #01b7ff;
}

.printout_upload_error {
  display: block !important;
}

.printout_upload_error p {
  white-space: pre-line;
}

.btn-toast-error {
  background-color: #ffc1c1;
  color: #051b7d;
}

.btn-toast-error:hover {
  background-color: #fff4f4;
  color: #051b7d;
}

.btn-purple {
  background-color: #000001;
  -webkit-box-shadow: 0px 3px 6px #a8a8a8;
  box-shadow: 0px 3px 6px #a8a8a8;
  color: #ffffff;
}

.pdf-buttons img {
  width: 15px;
  margin-right: 5px;
}

.btn-purple:hover {
  color: #ffffff;
  opacity: 0.8;
}

.btn-white {
  background-color: #ffffff;
  color: #000001;
  box-shadow: 0px 3px 6px #000C674D;
  border-radius: 5px;
}

.btn-white:hover {
  color: #000001;
  background-color: #F5F5F5;
}

.mobile-pdf-content {
  display: none;
}

.page-link:focus {
  box-shadow: none;
}

.section-space {
  padding: 60px 0;
}

.btm-text p {
  position: absolute;
  bottom: 0;
  right: 25px;
}

.text-grey {
  color: #707070;
}

.text-yellow {
  color: #BC8200;
}

.text-green {
  color: #009662;
}

.upload-prints.printout-thumbnails #thumbnails div .wrap_img_sec:hover:after {
  background: transparent;
}

.publication-modal .modal-dialog {
  max-width: 550px;
}

.publication-modal .modal-content {
  border-radius: 10px;
  border: none;
}

.publication-modal .modal-body {
  padding: 30px;
}

.publication-modal .modal-body label {
  font-size: 15px;
  margin-bottom: 0;
}

.publication-modal .modal-body form .form-control {
  border: 1px solid #000C6780;
  border-radius: 5px;
}

.publication-modal .modal-body form .form-control::placeholder {
  color: #000C67;
  opacity: 0.3;
}

.publication-modal .modal-body form .form-control.desc {
  height: 122px;
}

.publication-modal .modal-body form .form-control:focus {
  box-shadow: none;
}

.pdf-img {
  width: 40px;
}

.upload-new-file input[type="file"] {
  position: absolute;
  left: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

.upload-new-file>div {
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 2px dashed #a9a9a9;
  border-radius: 10px;
  text-align: center;
  flex-direction: column;
}

.uploaded-img {
  display: flex;
  padding: 0px 30px;
}

.back_button {
  position: absolute;
  top: 100px;
  color: #051b7d
}

.back_button a {
  padding: 10px;
  text-decoration: none;
  font-size: 17px;
}

.back_button a:hover {
  background-color: #f7f7f7;
  color: #051b7d;
  border-radius: 5px;
}

/*.upload-new-file div:before {left:0;right:0;border-radius:60px}*/
.upload-new-file>div>p {
  opacity: 0.8;
  color: #000001;
  font-size: 30px;
  display: block;
  width: 100%;
  line-height: 37px;
  margin: 0;
  padding: 55px 0px 50px;
}

label.upload-new-file {
  display: inline-block;
  position: relative;
  /*height: 193px;*/
  width: 400px;
  cursor: pointer;
}

.upload-new-file div.dragover {
  background-color: #aaa;
}

.upload-details .form-group input {
  border: 1px solid #C1C1C1;
  padding: 9px 10px 6px;
  height: auto;
}

.upload-details .form-group input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.3);
}

.upload-details .form-group input::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.3);
}

.upload-details .form-group input:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(0, 0, 0, 0.3);
}

.upload-details .form-group input:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.3);
}

.at-style-responsive .at-share-btn {
  padding-left: 0px !important;
  padding-bottom: 10px !important;
  margin-left: -2px !important;
}

.at-style-responsive .at-share-btn .at-label {
  color: #000c67 !important;
}

.at-style-responsive .at-share-btn svg {
  fill: #000c67 !important;
}

.at-resp-share-element .at-share-btn:focus,
.at-resp-share-element .at-share-btn:hover {
  transform: translateY(0px) !important;
}

.at-resp-share-element .at-share-btn:focus,
.at-resp-share-element .at-share-btn:hover .at-label,
.at-resp-share-element .at-share-btn:focus,
.at-resp-share-element .at-share-btn:hover svg {
  color: #01B7FF !important;
  fill: #01B7FF !important;
}

/* --------- select box --------- */

.flex {
  display: flex;
}

.spaceBetween {
  justify-content: space-between;
}


/* for select bropdown */
.texts {
  color: #051b7d;
  opacity: 0.5;
}

.options,
.inputBadgeCont,
.removeSearch {
  cursor: pointer;
  color: #051b7d;
}

.options {
  display: block;
}

.options.selected {
  color: #051b7d;
}

.dropdownsSelect span:hover {
  background-color: #ffffff;
}

.dropdownsSelect span:last-child {
  margin-bottom: 0;
}

.dropdownsSelect.selectOpen {
  height: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  /* border: 1px solid #e0d9d9; */
}

.selectOpen::-webkit-scrollbar-thumb {
  background: #888;
}

.dropdownsSelect {
  height: 0;
  overflow: hidden;
  transition: 0.3s;
  background-color: #ffff;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 100%;
  top: 35px;
  z-index: 99;
}

.itemCenter {
  align-items: center;
}

.input-default {
  padding-left: 14px;
}

.aeroIcon {
  transition: 0.4s;
}

.aeroIcon img {
  width: 18px;
  height: 10px;
}

.aeroIcon.rotate {
  transform: rotate(-90deg);
}

.inputBadgeCont,
.options {
  padding: 7px 14px;
  border: none;
}

.modal-link {
  margin: 7px 14px;
  border-bottom: 1px solid #dadada;
}

.selectCont {
  position: relative;
  box-shadow: 0px 3px 8px #c5c5c5;
  border-radius: .25rem;
}

.publicationfields .selectCont {
  position: relative;
  box-shadow: none;
  border-radius: .25rem;
  padding: 0px;
}

.selectCont.publication div:nth-of-type(1),
.selectCont.publication div:nth-of-type(1):hover,
.selectCont.week div:nth-of-type(1),
.selectCont.week div:nth-of-type(1):hover {
  border: none !important;
  box-shadow: none;
}

.selectCont.publication div svg,
.selectCont.week div svg:not(.selectCont > div > div > div > div svg),
.selectCont.period div svg:not(.selectCont > div > div > div > div svg) {
  color: #051b7d;
}

.selectCont>div>div>div>div svg:hover {
  color: #fff;
}

.main_cont select {
  width: 100%;
  height: 52px;
  background-color: unset;
  outline: none;
}

.texts.active {
  opacity: 1;
}

.form-control::-webkit-input-placeholder {
  color: #051b7d;
  opacity: 0.5;
}

/* WebKit, Blink, Edge */
.form-control:-moz-placeholder {
  color: #051b7d;
  opacity: 0.5;
}

/* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder {
  color: #051b7d;
  opacity: 0.5;
}

/* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder {
  color: #051b7d;
  opacity: 0.5;
}

/* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder {
  color: #051b7d;
  opacity: 0.5;
}

/* Microsoft Edge */

.form-control,
.form-control:focus {
  color: #051b7d;
}


/* ------------ choose file button ---------- */

label.custom-file {
  width: auto;
  display: inline-block;
  background-color: #ffffff;
  color: #000C67;
  box-shadow: 0px 3px 6px #000C674D;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-bottom: 10px;
}

.apply_all {
  font-size: 14px;
}

.view_printout {
  padding-left: 0;
}

.view_printout .close {
  z-index: 9;
  /*display:none;*/
}

/*--------------- ABOUT & CONTACT -----------------*/

.text-box-container {
  margin-top: 50px;
  /* background-color: #f4f5ff9c; */
  padding: 50px;
  /* box-shadow: -1px 4px 6px #00000026; */
  font-size: 18px;
  opacity: 0.9;
  border-radius: 16px;
}

.contact-form {
  width: 100%;
}

.wrap-contact {
  width: 550px;
  margin-left: auto;
  margin-right: auto;
}

.contact-form-contact-us {
  margin-top: 180px;
}

.contact-form-title {
  display: block;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 10px;
}

.contact-form-link {
  display: block;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-align: center;
  padding-bottom: 10px;
}

.contact-form-btn button {
  min-width: 150px;
  font-size: 18px;
}

.view_printout .modal-dialog {
  margin: 0;
  position: fixed;
  left: 0;
  top: 0;
  max-width: 900px;
  width: 100%;
  padding: 0;
  background: #ebf2ff;
  border-radius: 10px;
  max-height: 80%;
  margin: auto;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  pointer-events: all;
}

.view_printout .modal-content {
  background: transparent;
  border: 0;
}

.view_printout .col-md-6 {
  min-height: 70vh;
  padding-bottom: 40px;
}



@media (max-width: 1024px) {
  .view_printout .col-md-6 {
    min-height: 50vh;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .view_printout .modal-dialog {
    position: unset;
    left: unset;
    top: unset;
    transform: unset !important;
    margin: 30px 0;
    max-height: inherit;
  }

  /*.language-menu {
        display: none;
    }
    .mobile-language-menu{
        display: block;
    }
    .print_head:not(.mobile){
        display: none;
    }
    .print_head.mobile {
        display: block;
        margin-top: 70px;
        text-align: center;
        text-align: center;
    }*/
  div#translator_sec {
    margin-top: 20px;
  }

  h4.print_head {
    font-size: 25px;
  }

  .search-box {
    flex-basis: auto;
  }

  .printout-text h5 {
    font-size: 18px;
  }

  .printout-text span {
    font-size: 14px;
  }

  .publication-card .card-title {
    font-size: 18px;
    font-weight: 600;
  }

  .view-pdf-content {
    display: none;
  }

  .pdf-page-content {
    padding-top: 50px !important;
  }

  .pdf-container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .mobile-pdf-content {
    display: block;
    margin-top: 100px;
  }

  .login-button {
    font-size: 18px;
  }

  .login-block p {
    font-size: 20px;
  }

  .btm-text-mb p {
    display: block;
    position: absolute;
    left: 15px;
    margin-bottom: 30px;
  }

  .btm-text p {
    display: none;
  }

  h1.dld-icon {
    margin-top: -24px;
    padding-right: 15px;
    text-align: right;
    font-size: 22px;
  }

  h1.dld-icon img {
    width: 20px;
  }

  .mrt-4 {
    margin-top: 30px;
  }

  .navbar-light .navbar-toggler {
    border: none;
    padding: 0;
  }

  .modal-xl {
    padding: 5px
  }

  .pdf-buttons .btn {
    margin-bottom: 10px;
  }

  .view_printout .close {
    display: block;
    opacity: 0.7 !important;
  }

  .view_printout .img-prp {
    display: none;
  }

  .view_printout .pdf-content {
    margin-left: 0px !important;
  }

  .wrap-contact {
    width: auto;
  }

  .printout-thumbnails #thumbnails .active .wrap_img_sec:after {
    content: "";
    position: absolute;
    box-shadow: 0px 3px 6px #57cfff;
    width: 92%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 8px;
    transition: all 0.5s ease;
  }

  .printout-thumbnails #thumbnails .active .printout-text h5 {
    color: #01b7ff;
  }

  /* .react-pdf__Page__textContent{
        position:unset !important;
    } */
  .view_printout .col-md-6 {
    padding-left: 0;
    margin-top: 15px;
  }
}

@media(min-width: 768px) {
  .btm-text-mb p {
    display: none;
  }

  /* h1.dld-icon {
        display: none;
    } */
  h1.dld-icon {
    margin-top: -24px;
    padding-right: 15px;
    text-align: right;
    font-size: 22px;
  }

  h1.dld-icon img {
    width: 20px;
  }

  h1.dld-icon a:hover {
    color: #01b7ff !important;
  }

  .upload-btn {
    font-size: 30px;
    background: #b2b2b2 !important;
    line-height: 35px;
    margin: auto;
    display: table;
    padding: 12px 24px 7px;
    border: 0px !important;
    box-shadow: 0px 3px 5px #bfbfbf !important;
  }

  #thumbnails div .wrap_img_sec:hover .view_icons {
    width: 100%;
    display: block;
    position: absolute;
    top: calc(50% - 52px);
    /*bottom: 0;*/
    left: 0;
    color: #fff;
    z-index: 999;
    right: 0;
    transition: all 3s ease;
  }

  .printout-thumbnails #thumbnails .printout-card:hover .wrap_img_sec:after {
    content: "";
    position: absolute;
    box-shadow: 0px 3px 6px #57cfff;
    width: 92%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 8px;
    transition: all 0.5s ease;
  }

  .printout-thumbnails #thumbnails .printout-card:hover .printout-text h5 {
    color: #01b7ff;
  }

  .Toastify__toast {
    width: 600px;
    right: 45%;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1080px !important;
  }
}

@media screen and (max-width:575px) {
  #inner_drop_sec .col-sm-3 {
    text-align: center;
    margin-bottom: 18px;
  }

  #inner_drop_sec .col-sm-3 img {
    width: auto;
    height: 373px;
  }

  .pdf_sec {
    text-align: center !important;
    margin-top: 0;
  }

  #inner_drop_sec .react-pdf__Page canvas {
    max-width: 100%;
    height: auto !important;
    object-fit: cover;
    border: 0px;
    box-shadow: 0px 0px 4px #b8b8b8;
    border-radius: 5px;
  }
}

/*end*/


/*
.nav-item {
  font-size: 20px;
  line-height: 28px;
} */

.nav-link {
  text-align: right;
  font-family: Poppins !important;
  font-size: 18px !important;
  line-height: 27px;
  letter-spacing: 0px;
  color: #558EFA;
}

.nav-item.dropdown {
  width: 34px;
  height: 34px;
  background: #568FFF 0% 0% no-repeat padding-box;
  border-radius: 50%;
}

.nav-item.dropdown a.nav-link {
  padding: 7px 0px;
  text-align: center;
  font-family: Poppins !important;
  font-weight: bold;
  font-size: 14px !important;
  line-height: 21px;
  letter-spacing: 0px;
  color: #FFFFFF !important;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu.show {
  /*background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;*/
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000019;
  border-radius: 10px;
  color: #558EFA;
}

/*.dropdown-item {*/
/*  font: normal normal medium 14px/21px Poppins;*/
/*  letter-spacing: 0px;*/
/*  color: #558EFA;*/
/*}*/

.dropdown-item {
  font: 500 14px/21px 'Poppins', sans-serif;
  letter-spacing: 0;
  color: #558EFA;
}


.nav-link {
  text-align: right;
  font-family: Poppins !important;
  font-size: 18px !important;
  line-height: 27px;
  letter-spacing: 0px;
  color: #558EFA;
}

.nav-item.dropdown a.nav-link {
  padding: 7px 0px;
  text-align: center;
  font-family: Poppins !important;
  font-weight: bold;
  font-size: 14px !important;
  line-height: 21px;
  letter-spacing: 0px;
  color: #FFFFFF !important;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu.show {
  /*background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;*/
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000019;
  border-radius: 10px;
  color: #558EFA;
}

/*.dropdown-item {*/
/*  font: normal normal medium 14px/21px Poppins;*/
/*  letter-spacing: 0px;*/
/*  color: #558EFA;*/
/*}*/

.dropdown-item {
  font: 500 14px/21px 'Poppins', sans-serif; /* Correct font shorthand */
  letter-spacing: 0; /* No units needed for zero value */
  color: #558EFA;
}

.search_block {
  text-align: center;
  padding: 65px 0 75px;
  background: rgb(83, 140, 254);
  width: 100%;
  background: linear-gradient(106deg, rgb(83, 140, 254) 0%, rgb(56, 94, 202) 100%);
}

.search_block h1 {
  font-size: 40px;
  color: #fff;
  line-height: 65px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  margin: 0;
}

.search_block p {
  font-size: 25px;
  color: #fff;
  line-height: 33px;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

.search_block .ais-SearchBox {
  max-width: 500px;
  margin: 40px auto 0;
}

.styles_autocomplete_container__2-j4o {
  max-width: 70%;
  margin: 0px auto;
}

.search_block .ais-SearchBox-form,
.search_block .ais-SearchBox-input {
  border-radius: 10px;
}

.search_block .ais-SearchBox-input {
  box-shadow: inset 0 1px 4px 0 rgba(119, 122, 175, .3) !important
}

.title_block{
  display: block;
  max-height: 200px;
}

.top-footer {
  background: #558EFF47;
  padding: 13px 0 11px;
}

.top-footer p {
  color: #385ECA;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.bottom-footer {
  background: #568FFF;
  padding: 25px 0 18px;
}

.bottom-footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bottom-footer ul li {
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  float: left;
  margin-right: 40px;
}

.bottom-footer ul li:last-child {
  margin-right: 0px;
}

.bottom-footer ul li a {
  float: left;
  font-size: 20px;
  line-height: 28px;
}

.bottom-footer ul li a:hover {
  color: #fff;
  /* text-decoration: none; */
}

.bottom-footer .col:last-child p {
  color: #fff;
  font-size: 16px;
  line-height: 28px;
  text-align: right;
  margin: 0;
  margin-left: auto;
}

.bottom-footer .col {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.footer-logo {
  float: left;
  margin-right: 35px;
}


.filter-block #translator_sec {
  margin: 0;
}

.filter-block {
  background: #558EFF47;
  padding: 21px 0 20px;
}

.language-filter {
  float: left;
  position: relative;
}

.language-filter label {
  font-size: 18px;
  line-height: 34px;
  color: #3465D1;
  font-weight: 700;
  margin: 0;
}

.language-filter label span,
.button-week div {
  background: #558EFF;
  border-radius: 5px;
  color: #fff;
  padding: 10px 12px 7px;
  margin-left: 8px;
  font-size: 17px;
  line-height: 25px;
  cursor: pointer;
}

.language-filter div span:hover {
  background: #3355B4;
}

.week_control_buttons {
  text-align: center;
}

.week_control_buttons div {
  position: relative;
  /* float: right; */
}

.week_control_buttons div div {
  position: relative;
  /* float: left; */
}

/* .week_control_buttons div {
    font-size: 18px;
    line-height: 34px;
    color: #3465D1;
    font-weight: 700;
    margin: 0;
}
.week_control_buttons div .weekButtons {
    background: #558EFF;
    border-radius: 5px;
    color: #fff;
    padding: 10px 30px 7px;
    margin-left: 8px;
    font-size: 17px;
    line-height: 25px;
    cursor: pointer;
}
.week_control_buttons div  .weekButtons:hover {
    background: #3355B4;
} */

.weekButton {
  background: #558EFF;
  border-radius: 5px;
  padding: 4px 10px 1px 10px;
  margin-left: 10px;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  line-height: 34px;
  color: #3465D1;
  font-weight: 700;
  margin-bottom: 2px;
}

.weekButton span {
  color: #fff;
}

.weekButton span:first-child {
  margin-right: 25px;
  color: #fff;
}

.weekButton:hover {
  background: #3355B4;
}

.disabledButton {
  background-color: grey;
}

.disabledButton:hover {
  background-color: grey;
}

div#translator_sec .language-filter ul.language-menu {
  padding: 10px 0;
  margin: 0;
  position: absolute;
  right: -20px;
  width: 140px;
  background: #fff;
  border-radius: 5px;
  top: calc(100% + 10px);
  z-index: 9;
  box-shadow: 0px 2px 6px #0000004D;
  /* display:none;*/
}

div#translator_sec .language-filter ul.language-menu li.tab-heading-01 {
  padding: 0 15px 0 36px;
  margin: 7px 0;
  background: transparent !important;
  width: 100%;
  display: table;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  color: #000001 !important;
}

div#translator_sec .language-filter ul.language-menu li.tab-heading-01 span:hover {
  color: blue !important;
}

div#translator_sec .language-filter ul.language-menu li.tab-heading-01 svg {
  position: absolute;
  left: 15px;
  top: 1px;
  z-index: -9;
}

div#translator_sec .language-filter ul.language-menu li.tab-heading-01 svg.filterchecked {
  display: none;
}

div#translator_sec .language-filter ul.language-menu li.tab-heading-01.active svg.filterchecked {
  display: block;
}

div#translator_sec .language-filter ul.language-menu::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  top: -8px;
  right: 0;
  left: 0;
  margin: auto;
}

.printthumb_outer.printout-thumbnails #thumbnails .cols {
  margin: 0 0 20px 0;
  padding: 0 17px;
  position: relative;
}

.printthumb_outer.printout-thumbnails .wrap_img_sec .pdf_data_outer {
  margin: 0 0 10px;
  padding: 43px 20px 0;
  border-radius: 10px;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  height: 200px;
  overflow: hidden;
}

.printthumb_outer.printout-thumbnails .wrap_img_sec .pdf_data_outer::before {
  background: #e7efff;
  position: absolute;
  left: 0;
  top: 23px;
  width: 100%;
  border-radius: 10px;
  content: "";
  box-shadow: 0px -6px 5px inset #DDD;
  bottom: 0;
}

.printthumb_outer.printout-thumbnails .wrap_img_sec .pdf_data_outer .image {
  position: relative;
  margin: 0px;
  transition: margin 0.6s;
  box-shadow: 0px 0px 3px #c4c4c4;
  aspect-ratio: 1;
}

.printthumb_outer.printout-thumbnails #thumbnails .printout-card:hover .wrap_img_sec::after {
  display: none;
}

.printthumb_outer.printout-thumbnails .wrap_img_sec .pdf_data_outer .image:hover {
  margin-top: -40px;
}

.printthumb_outer .printout-text h5 {
  margin-top: 17px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #000001 !important;
}

.printthumb_outer #thumbnails .text-left {
  margin-left: 0px;
  margin-top: 3px;
}

.printthumb_outer #thumbnails .text-left span {
  font-size: 16px;
  line-height: 20px;
}

.printthumb_outer.home_printthumb_outer #thumbnails .text-left span {
  color: #000001;
}

.printthumb_outer #thumbnails h1.dld-icon {
  margin: 0;
  padding: 0;
  text-align: right;
  font-size: 22px;
  position: absolute;
  right: 17px;
  bottom: 0;
}

.printthumb_outer #thumbnails h1.dld-icon i {
  color: #000001;
  font-size: 19px;
}


.section-space.uploadpg-block {
  padding: 55px 0 150px;
}

#new-file {
  color: #000001;
}


#publictaion_sec p {
  color: #000001;
  font-size: 25px;
  line-height: 31px;
}

#inner_drop_sec .apply_all i {
  margin-right: 5px;
}

#inner_drop_sec .selectCont.publication div svg,
#inner_drop_sec .selectCont.week div svg:not(.selectCont > div > div > div > div svg),
#inner_drop_sec .selectCont.period div svg:not(.selectCont > div > div > div > div svg) {
  color: #000001;
}

.upload-pg-btns button {
  color: #000001 !important;
  padding: 12px 20px 8px;
  font-size: 19px;
  line-height: 25px;
}

.upload-pg-btns button:last-child {
  color: #fff !important;
  background: #000001 !important;
}


.categorybanner_block {
  padding: 65px 0 75px;
  background: #7ba5f7;
  width: 100%;
}

.categorybanner_block_left {
  text-align: right;
  max-width: 50%;
}

.categorybanner_block_left div {
  /*background: #fff;
    padding: 10px 15px;
    box-shadow: 0px 0px 5px #d5d5d5;*/
  border-radius: 7px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  margin-right: 5px;
  max-width: 100%;
}

.categorybanner_block_right h4 {
  font-size: 26px;
  line-height: 33px;
  color: #fff;
  font-weight: 700;
  margin: 0 0 0 5px;
}

.categorybanner_block_right p {
  font-size: 20px;
  line-height: 25px;
  color: #fff;
  margin: 0 0 15px 5px;
}

.categorybanner_block_right a {
  font-size: 20px;
  line-height: 25px;
  color: #fff;
  margin: 0 0 0 5px;
}


.filter-block {
  background: #558EFF47;
  padding: 13px 0 11px;
  position: relative;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(82,138,252, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.language-filter.sort-filter {
  float: right;
}

.language-filter label span:hover,
.button-week div:hover,
.language-filter.categorysort-filter label span:hover,
.language-filter.sort-filter label span.activefilter,
.language-filter.categorysort-filter label span.activefilter {
  background: #3355B4;
}

/* .search_container .week_control_buttons span , .search_container .week_control_buttons span:hover{
    background: #3355B4;

} */
/* .search_container .print_head_parent{
    display: flex;
    align-items: center;

} */

.upload-btn {
  font-size: 30px;
  background: #b2b2b2 !important;
  line-height: 35px;
  margin: auto;
  display: table;
  padding: 12px 24px 7px;
  border: 0px !important;
  box-shadow: 0px 3px 5px #bfbfbf !important;
}



.add_publication .form-group input,
.add_publication .language span,
.add_publication .form-group textarea {
  color: #000001;
}

.add_publication .form-group input::-webkit-input-placeholder,
.add_publication .form-group textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.3);
}

.add_publication .form-group input::-moz-placeholder,
.add_publication .form-group textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.3);
}

.add_publication .form-group input:-ms-input-placeholder,
.add_publication .form-group textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(0, 0, 0, 0.3);
}

.add_publication .form-group input:-moz-placeholder,
.add_publication .form-group textarea:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.3);
}

.add_publication label.custom-file {
  color: #000001;
  font-weight: 700;
}

.add_publication .language span.texts.active {
  opacity: 0.1;
}

button.add_publication-btn {
  background-color: #000001;
  -webkit-box-shadow: 0px 3px 6px #a8a8a8;
  box-shadow: 0px 3px 6px #a8a8a8;
  padding: 8px 15px 4px;
}


.view_printout .pdf-buttons a.btn {
  clear: both;
  display: table;
  margin: 10px 0 0;
}

.view_printout .addthis_inline_share_toolbox .at-share-btn {
  background-color: transparent !important;
  border-radius: 0px;
  padding: 0 !important;
  margin: 0 15px 0 0 !important;
}

.view_printout .addthis_inline_share_toolbox .at-share-btn .at-label {
  display: none !important;
}

.view_printout .addthis_inline_share_toolbox .at-share-btn .at-icon {
  width: 25px !important;
}

.view_printout .addthis_inline_share_toolbox .at-share-btn .at-icon-wrapper {
  width: 25px !important;
}

.view_printout .addthis_inline_share_toolbox .at-style-responsive .at-share-btn svg {
  fill: #000001 !important;
}

header .navbar-brand>div {
  display: block !important;
}

header.py-header {
  z-index: 9;
  position: relative;
}

main,
footer {
  position: relative;
}

.menuOpen main::after,
.menuOpen footer::after {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  content: "";
  left: 0;
  top: 0;
}

.navbar-light .navbar-toggler {
  width: 24px;
  height: 17px;
  position: relative;
}

.navbar-light .navbar-toggler span {
  position: absolute;
  width: 100%;
  height: 2px;
  top: 0;
  background: #528AFC;
  background-image: none;
  border-radius: 5px;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  left: 0;
}

.navbar-light .navbar-toggler::before {
  content: "";
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  height: 2px;
  background: #528AFC;
  border-radius: 5px;
  transition: 0.25s;
}

.navbar-light .navbar-toggler::after {
  content: "";
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 2px;
  background: #528AFC;
  border-radius: 5px;
  left: 0;
  transition: 0.25s;
}

.menuOpen .navbar-light .navbar-toggler span {
  height: 0px;
  background: transparent;
}

.menuOpen .navbar-light .navbar-toggler::before {
  transform: rotate(36deg);
}

.menuOpen .navbar-light .navbar-toggler::after {
  top: 8px;
  transform: rotate(-36deg);
}


/* popup new design css */

.view_printout .modal-body {
  padding: 0;
}

.view_printout .close {
  z-index: 9;
  display: block !important;
  position: absolute;
  left: 20px;
  padding-right: 0 !important;
  opacity: 1 !important;
}

.view_printout .close span {
  color: #fff !important;
}

.view_printout .close span:hover {
  color: #cdcaca !important;
}

.view_printout_header {
  background: #2A5AC6;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 0 11px;
  position: relative;
}

.view_printout_header h4 {
  color: #fff;
  margin: auto;
  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
}

.view_printout_header a:hover {
  color: #cdcaca !important;
}

.view_printout_header a {
  font-weight: 700;
  float: left;
  color: #fff !important;
  margin: 0 7px 0 0;
  text-decoration: none;
}

.view_printout_header p {
  margin: 0;
  margin-right: 7px;
  float: left;
}

.view_printout_header .pdf-buttons {
  position: absolute;
  right: 25px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 22px;
}

.view_printout_header .pdf-buttons .btn {
  padding: 0;
  float: left;
  clear: none !important;
  margin: 0 10px !important;
}

.view_printout_header .pdf-buttons .btn:last-child {
  margin-right: 0 !important;
}

.view_printout_header .fa {
  color: #fff !important;
  font-size: 20px;
  line-height: 20px;
  float: left;
}

.view_printout_header .fa:hover {
  color: #cdcaca !important;
}

.view_printout .modal-dialog {
  max-height: 90vh;
}

.view_printout_pdf.MyComponent {
  height: calc(90vh - 100px);
}

.view_printout_footer {
  background: #2A5AC6;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 0 7px;
  position: relative;
  min-height: 50px;
}

.view_printout_footer .pdf-details {
  padding: 0;
  list-style: none;
  margin: 0 0 0 5px;
}

.view_printout_footer .pdf-details li {
  float: left;
  margin: 0 20px;
  color: #fff !important;
  position: relative;
}

.view_printout_footer .pdf-details li::before {
  position: absolute;
  content: ".";
  font-size: 30px;
  line-height: 11px;
  left: -23px;
}

.view_printout_footer .pdf-details li:first-child::before {
  display: none;
}

.view_printout_footer .addthis_inline_share_toolbox {
  margin-right: 5px;
}

.view_printout_footer .addthis_inline_share_toolbox .at-style-responsive .at-share-btn svg {
  fill: #fff !important;
}


/* pdf page new design css */

.pdf-page-main.pdf-page-content {
  padding: 0 !important;
  height: 100%;
}

.pdf-page-main .view_printout_header .pdf-buttons {
  right: 15px;
}

.view_printout_header .col-md-12,
.view_printout_footer .col-md-12 {
  display: flex;
  align-items: center;
  width: 100%;
}

.pdf-page-main .view_printout_footer .pdf-details {
  margin: 0;
}

.pdf-page-main .view_printout_footer .pdf-details li:first-child {
  margin-left: 0px;
}

.pdf-page-main .view_printout_footer .pdf-details li:last-child {
  margin-right: 0px;
}

.pdf-page-main .view_printout_footer .addthis_inline_share_toolbox {
  margin-right: 0;
}

.view_printout_footer_inner {
  margin-left: auto;
}

.pdf-page-main .view_printout .addthis_inline_share_toolbox .at-share-btn:nth-child(2) {
  margin-right: 0 !important;
}

.pdf-page-content-center {
  margin: 40px 0 55px;
}

.pdf-page-main .view_printout_footer {
  position: absolute;
  bottom: 0;
}

.pdf-page-main .react-pdf__Page canvas {
  margin: auto;
}

.pdf-details.mobile,
.view_printout_footer .pdf-buttons {
  display: none;
}


.view_printout .addthis_inline_share_toolbox .at-share-btn.at-svc-email {
  display: none !important;
}

.page-title-block {
  background: #568FFF;
  padding: 18px 0 18px;
  color: #FFFFFF;
  position: relative;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: left;
  font: normal normal bold 20px/28px Acumin Pro;
  letter-spacing: 0px;
}

.following-publications-details {
  display: inline-block;
  text-align: left;
  font: normal normal normal 20px/30px Poppins;
  letter-spacing: 0px;
  color: #000000;
  padding: 0.75rem;
}

.following-publications .publication-card img {
  max-width: 150px;
}

.following-publications .custom-control-input:checked~.custom-control-label::before {
  border-color: #568FFF;
  background-color: #568FFF;
}

.following-publications .custom-control-label::before {
  border: #568FFF solid 1px;
}

.following-publications .custom-switch .custom-control-label::after {
  background-color: #568FFF;
}

.following-publications .custom-control-input:checked~.custom-control-label::after {
  background-color: #FFFFFF !important;
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
  border: #9ABCFF solid 1px;
  background-color: #9ABCFF;
}

.custom-switch .custom-control-input[disabled]~.custom-control-label::before {
  background-color: #FFFFFF;
  border: #9ABCFF solid 1px;
}

.custom-switch .custom-control-input[disabled]~.custom-control-label::after {
  background-color: #9ABCFF;
}

/* for md */

.custom-switch.custom-switch-md .custom-control-label {
  padding-left: 2rem;
  padding-bottom: 1.5rem;
}

.custom-switch.custom-switch-md .custom-control-label::before {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.custom-switch.custom-switch-md .custom-control-label::after {
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
  border-radius: calc(2rem - (1.5rem / 2));
}

.custom-switch.custom-switch-md .custom-control-input:checked~.custom-control-label::after {
  transform: translateX(calc(1.5rem - 0.25rem));
}

.following-block .section-title {
  text-align: left;
  font-size: 20px;
  line-height: 30px;
  font-family: Poppins;
  font-weight: 500;
  letter-spacing: 0px;
  color: #000001;
}

.customButton {
  /*color: var(--unnamed-color-568fff);*/
  text-align: right;
  font: normal normal bold 20px/30px Poppins;
  letter-spacing: 0px;
  color: #568FFF;
  background: none;
  border: none;
  padding: 0px;
}

.customButton:hover {
  background-color: #385eca;
}

.customButton:disabled {
  opacity: 0.5;
}

.following-publications-table thead th {
  border: none;
  font: normal normal normal 20px/30px Poppins;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 500;
  padding-bottom: 35px;
}

.following-publications-table tbody td {
  text-align: left;
  font: normal normal normal 20px/30px Poppins;
  letter-spacing: 0px;
  color: #000001;
}

.following-publications-table tbody .vertical-icon {
  width: 0%;
  font-size: 25px;
  transition: visibility 0s, opacity 0.5s linear;
}

.following-publications-table tbody .publication-logo {
  width: 17%;
}

.combined-pdf-checkbox input {
  width: 22px;
  height: 22px;
  accent-color: #568FFF;
}

.combined-pdf-checkbox label {
  margin-left: 10px;
}

.following-publications-details .head-title {
  font-weight: 500;
}

.timezone-picker-textfield input {
  padding: 0.375rem 0.75rem !important;
  font: normal normal 300 16px/25px Poppins !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  color: #051b7d !important;
  font-weight: 400 !important;
}

.following-publications-details .timezone-picker ul li,
.following-publications-details input::placeholder {
  color: #051b7d !important;
  font-size: 1rem;
  opacity: 1;
}

.timezone-picker-textfield::before {
  position: absolute;
  content: "";
  top: 12px;
  right: 7px;
  border: solid #051b7d;
  border-width: 0 2px 2px 0;
  padding: 2.5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

@media(max-width:991px) {
  header .navbar-brand {
    /* order: 2; */
    margin-left: 20px;
    margin-right: auto;
  }

  .navbar-light .navbar-toggler {
    border: none;
    padding: 0;
  }

  .nav-item.dropdown {
    padding: unset;
  }

  .collapse:not(.show) {
    display: block;
  }

  .collapse:not(.show) .nav-item.dropdown {
    display: none;
  }
}

@media (min-width: 991px) {
  .nav-bar-items {
    display: flex;
  }
}

@media(max-width:767px) {
  .week_control_buttons {
    display: flex;
  }
  .week_control_buttons div {
    max-width: 46%;
    /* float: left; */
    display: flex;
  }

  .week_control_buttons div:nth-child(1) span:nth-child(2), .week_control_buttons div:nth-child(2) span:nth-child(1){
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .week_control_buttons div div {
    float: left;
  }

  .categorybanner_block_right h4 {
    font-size: 20px;
    margin: 0;
    line-height: 30px;
  }

  .categorybanner_block_right p {
    font-size: 16px;
    line-height: 23px;
    margin: 0 0 15px 0;
  }

  .categorybanner_block_right a {
    font-size: 16px;
    line-height: 25px;
    margin: 0;
  }

  .footer-logo {
    margin-right: 30px;
  }

  .view_printout .pdf-buttons a.btn {
    display: inline-block;
    margin: 0 0 10px 20px;
  }

  /*.view_printout .close {
        display: none;
    }*/
  .view_printout .modal-content .modal-body>section {
    padding-left: 0;
    padding-right: 0;
  }

  .view_printout {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .language-filter.sort-filter {
    float: left;
    margin: 13px 0 0;
  }

  .pdf-details.desktop,
  .view_printout_header .pdf-buttons,
  .social-share-btn.desktop {
    display: none;
  }

  .view_printout_footer .pdf-buttons,
  .view_printout_footer .col-md-12,
  .view_printout_footer {
    display: block;
  }

  .view_printout_footer .pdf-details.mobile {
    margin: 0px auto;
    padding: 5px 0;
    display: table;
  }

  .pdf-page-main .view_printout_footer .pdf-details.mobile {
    padding: 20px 0 15px;
  }

  .view_printout_footer_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }

  .pdf-page-main .view_printout_footer_inner {
    padding: 0 0 15px;
  }

  .view_printout_footer .pdf-buttons .btn {
    color: #fff !important;
    padding: 0 !important;
    float: left;
    clear: none !important;
    margin: 0 17px !important;
    font-size: 18px;
    line-height: 15px !important;
    width: 25px !important;
  }

  .view_printout .addthis_inline_share_toolbox .at-share-btn {
    margin: 0 17px !important;
  }

  .pdf-page-main .view_printout .addthis_inline_share_toolbox .at-share-btn:nth-child(2) {
    margin-right: 17px !important;
  }

  .view_printout_pdf.MyComponent {
    height: calc(90vh - 134px);
  }

  .view_printout .view_printout_header h4 {
    margin-left: 20px;
  }

  .view_printout .close {
    left: auto;
    right: 20px;
  }
}

/**
my account page
**/

.myaccount {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  font-family: 'Poppins', sans-serif;
}

.myaccount p {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.myaccount__main {
  margin: 24px 0;
  flex-grow: 1;
}

.myaccount__header {
  background-color: rgb(83, 140, 254);
  padding: 12px 18px;
}

.myaccount__sidebar {
  flex-basis: 20%;
  flex-grow: 1;
  /* flex-shrink: 1; */
  padding-right: 24px;
  border-right: 1px solid rgba(83, 140, 254, 0.5);

}

.myaccount__content {
  flex-basis: 80%;
  flex-grow: 1;
  padding-left: 16px;
}

.myaccount__user {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.myaccount__thumbnail {
  height: 52px;
  width: 52px;
  border-radius: 9999px;
  background-color: rgb(83, 140, 254);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 400;
  line-height: 52px;
  font-size: 20px;
}

.myaccount__thumbnail__initials {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  line-height: 24px;
}

.myaccount__info {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.sidebar {}

.sidebar__item-wrapper {
  display: flex;
  flex-direction: column;
  width: max-content;
}

.sidebar__icon {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-right: 8px;
}

.myaccount__container {
  display: flex;
  height: 100%;
}

.sidebar__item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  /* align-self: flex-start; */
}

.sidebar__item:hover {
  background-color: rgba(83, 140, 254, 0.2);
}

.sidebar__item.active {
  background-color: rgba(83, 140, 254, 0.2);
}

.sidebar a {
  text-decoration: none;
  color: #385eca;
  font-size: 18px;
  line-height: 18px;
}

.form__group {
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
}

.form__label {
  margin: 0;
  text-transform: capitalize;
}

.form__input {
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #000;
}

.reset-password {
  margin-top: 32px;
}

.input__password {
  border: 1px solid #000;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-right: 8px;
}

.input__password img {
  height: 26px;
  width: 26px;
  border-radius: 50%;
}

.input__password img:hover {
  background-color: rgba(83, 140, 254, 0.5);
}

.input__password img:active {
  background-color: rgb(83, 140, 254);
}

.input__password input {
  outline: none;
  border: none;
  flex-grow: 1;
}

.myaccount__submit,
.myaccount__reset {
  border: none;
  outline: none;
  background-color: rgb(83, 140, 254);
  display: block;
  width: 100%;
  padding: 10px 12px;
  text-transform: uppercase;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
  line-height: 14px;
}

.myaccount__form button:hover {
  opacity: 0.8;
}

.myaccount__form {
  /* align-self: flex-start; */
}

.myaccount button:disabled {
  background-color: rgba(119, 122, 175, .3);
  cursor: not-allowed;
}

.myaccount__reset {
  background-color: #FA567C;
}

.edit-profile {
  display: flex;
  flex-direction: column;
  width: 320px;
}

@media(max-width:768px) {
  .myaccount__container {
    flex-direction: column;
  }

  .myaccount__main {
    margin: 0;
  }

  .myaccount__sidebar {
    border-right: unset;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: unset;
    /* padding-left: 24px; */
  }

  .edit-profile {
    width: 100%;
    /* margin-top: 24px; */
    margin-bottom: 40px;
  }

  .sidebar__item-wrapper {
    width: 100%;
  }

  .myaccount__content {
    padding-left: 0;
  }

  .myaccount .section-space {
    padding: 24px 0;
  }
}

@media(max-width:640px) {
  .search_block h1 {
    font-size: 35px;
    line-height: 45px;
    margin: 0 0 10px;
  }

  .search_block .ais-SearchBox {
    max-width: 100%;
    margin: 35px auto 0;
  }

  .styles_autocomplete_container__2-j4o {
    max-width: 100%;
  }

  .footer-logo {
    margin-right: 20px;
  }

  .bottom-footer ul li {
    font-size: 17px;
    margin-right: 20px;
  }

  .bottom-footer ul li a {
    font-size: 17px;
  }

  .categorybanner_block .row {
    display: block;
  }

  .categorybanner_block_left,
  .categorybanner_block_right {
    text-align: left;
    max-width: 300px;
    margin: auto;
  }

  .categorybanner_block_left div {
    margin: 0 0 25px;
  }

  .view_printout_footer .pdf-buttons .btn {
    margin: 0 10px !important;
  }

  .view_printout .addthis_inline_share_toolbox .at-share-btn {
    margin: 0 10px !important;
  }

  .pdf-page-main .view_printout .addthis_inline_share_toolbox .at-share-btn:nth-child(2) {
    margin-right: 10px !important;
  }

  .view_printout_footer .pdf-details li {
    margin: 0 10px;
  }

  .view_printout_footer .pdf-details li::before {
    font-size: 23px;
    line-height: 14px;
    left: -13px;
  }

  .view_printout_header h4 {
    font-size: 15px;
  }
}


@media(max-width:450px) {

  .printthumb_outer.printout-thumbnails #thumbnails .cols,
  .publication-cards .col {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .publication-card img {
    max-width: inherit;
  }

  .bottom-footer .col {
    flex: 0 0 100%;
  }

  .bottom-footer .col {
    justify-content: center;
  }

  .bottom-footer .col:last-child p {
    text-align: center;
    margin: 15px auto 0;
  }

  .section-space .printout-thumbnails .wrap_img_sec {
    display: none;
  }

  .section-space .printthumb_outer.printout-thumbnails #thumbnails .cols {
    flex: 0 0 92%;
    margin: 0px auto 20px;
    background: #CFDFFF;
    padding: 3px 20px 10px;
    border-radius: 8px;
  }

  .section-space .printthumb_outer #thumbnails h1.dld-icon {
    right: 20px;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 25px;
  }

  .section-space .printthumb_outer #thumbnails h1.dld-icon i {
    font-size: 25px;
  }

  .language-filter label {
    font-size: 16px;
  }

  .language-filter label span,
  .button-week div {
    padding: 10px 11px 7px;
    font-size: 15px;
  }
}

@media(max-width:550px) {

  .following-publications-table thead th,
  .following-publications-table tbody td,
  .following-publications-details,
  .following-publications-details .form-control,
  .following-publications-details .timezone-picker ul li,
  .following-publications-details input::placeholder,
  .customButton {
    font-size: 16px !important;
  }

  .following-publications-table thead th,
  .following-publications-table tbody td {
    padding: 10px;
  }

  .following-publications .publication-card img {
    max-width: 100px;
  }

  .following-publications-table tbody td svg {
    font-size: 18px !important;
  }

  .combined-pdf-checkbox input {
    width: 18px;
    height: 18px;
  }

  /* for sm */

  .custom-switch.custom-switch-sm .custom-control-label {
    padding-left: 1rem;
    padding-bottom: 1rem;
  }

  .custom-switch.custom-switch-sm .custom-control-label::before {
    height: 1rem;
    width: calc(1rem + 0.75rem);
    border-radius: 2rem;
  }

  .custom-switch.custom-switch-sm .custom-control-label::after {
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    border-radius: calc(1rem - (1rem / 2));
  }

  .custom-switch.custom-switch-sm .custom-control-input:checked~.custom-control-label::after {
    transform: translateX(calc(1rem - 0.25rem));
  }
}

@media(max-width:430px) {

  .following-publications-table thead th,
  .following-publications-table tbody td,
  .following-publications-details,
  .following-publications-details .form-control,
  .following-publications-details .timezone-picker ul li,
  .following-publications-details input::placeholder,
  .customButton {
    font-size: 12px;
    line-height: 20px;
  }

  .following-publications-table thead th,
  .following-publications-table tbody td {
    padding: 8px;
  }

  .following-publications .publication-card img {
    max-width: 80px;
  }

  .combined-pdf-checkbox input {
    margin-top: 2px;
  }
}

@media (max-width: 300px) {
  .form-content {
    padding: 15px;
  }
}

@media (max-width: 380px) {

  .following-publications-table thead th,
  .following-publications-table tbody td,
  .following-publications-details,
  .following-publications-details .form-control,
  .following-publications-details .timezone-picker ul li,
  .following-publications-details input::placeholder,
  .customButton {
    font-size: 10px !important;
    line-height: 18px;
  }

  .following-publications .publication-card img {
    max-width: 70px;
  }

  .combined-pdf-checkbox input {
    width: 15px;
    height: 15px;
  }

  .login-button {
    padding: 0;
    font-size: 16px;
  }
}

.following-tooltip {
  position: relative;
}

.following-tooltip label {
  cursor: pointer;
}

.following-tooltip .tooltiptext.weekly {
  left: -15%;
  bottom: 70%;
}

.following-tooltip .tooltiptext {
  visibility: hidden;
  /* width: 100px; */
  font-size: 14px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0 5px;
  position: absolute;
  z-index: 1;
  left: 15%;
  bottom: 0;
}

.following-tooltip:hover .tooltiptext {
  visibility: visible;
}

svg.icon-hover-effect:hover {
  color: #888 !important
    /* font-size: 24px !important;
  margin-right: 6px !important; */
}

td.align-middle.text-right svg:hover{
  fill: #3355B4!important;

}
.customButton svg{
  width: 16px;
  height: 16px;
  fill: #fff;
}


button.customButton .pl-0.pr-2 {
  border: 1px solid;
  float: left;
  width: 14%;
  padding: 0px!important;
  border-radius: 5px;
}

.customButton span {

}
.pr-2, .px-2 {
  padding-right: 0px!important;
}
button.customButton.pl-0.pr-2 {
  border: 1px solid;
  border-radius: 15px;
  padding: 0 10px!important;
  background: #568FFF!important;
  color: #fff;
  font-weight: 500;
}
.customButton svg {
  width: 16px;
  height: 16px;
  fill: #fff;
  position: relative;
  top: -2px;
}

span.weekprint {
    margin: 0 0 0 10px;
}
.following-publications .custom-control-input:checked~.custom-control-label:hover::before {
  border-color: #568FFF!important;
  background-color: #3355B4!important;
}
button.customButton.pl-0.pr-2:hover {
  border: 1px solid;
  border-radius: 15px;
  padding: 0 10px!important;
  background: #3355B4!important;
  color: #fff!important;
  font-weight: 500;
}
.ads-container{
  aspect-ratio: 6.83;
}
.video1{
  width: 100%;
  max-height: 100%;
}
